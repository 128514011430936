<template>
  <div class="components__editor">
    <div id="editor"></div>
  </div>
</template>

<script>
import E from 'wangeditor'
import localStore from 'store'
import { baseUrl } from '@/config/env'

export default {
  model: {
    prop: 'content',
    event: 'change'
  },
  props: {
    content: String
  },
  data() {
    return {
      editor: null
    }
  },
  watch: {
    content(v) {
      if (this.editor) {
        this.editor.txt.html(v)
      }
    }
  },
  async mounted() {
    await this.initEditor()
  },
  methods: {
    initEditor() {
      this.editor = new E('#editor')
      this.configEditor()
      this.editor.create()
    },
    configEditor() {
      const editor = this.editor
      editor.config.uploadImgServer = baseUrl + '/upload'
      editor.config.uploadFileName = 'file'
      editor.config.uploadImgMaxLength = 1
      this.editor.config.zIndex = 0
      editor.config.uploadImgHooks = {
        customInsert: (insertImgFn, result) => {
          insertImgFn(result.data)
        }
      }
      editor.config.uploadImgHeaders = {
        token: localStore.get('feisi-user')?.token
      }
      editor.config.onchange = () => {
        const content = this.getContent()
        this.$emit('change', content)
      }
    },
    getContent() {
      return this.editor.txt.html()
    }
  }
}
</script>

<style lang="scss" scoped>
.components__editor {
  ::v-deep .w-e-toolbar {
    // z-index: 0 !important;
  }
  ::v-deep .w-e-text-container {
    // z-index: 0 !important;
  }
}
</style>
